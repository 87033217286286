import styled from 'styled-components';
import theme from '../../theme';

export const Row = styled.div`
	display: flex;
	padding: 0 10px;

	@media (max-width: 800px) { 
		flex-direction: column;
	}

`;

export const Pill = styled.div`
	background-color: ${props => (props.active ? 'white' : theme.pink.dark)};
	padding: 4px 10px;
	border: 1px solid ${props => (props.active ? theme.pink.dark : 'transparent')};
	color: ${props => (props.active ? theme.pink.dark : 'white')};
	border-radius: 20px;
	margin: 10px 4px;
	font-size: 0.9em;

	&:hover {
		cursor: pointer;
		box-shadow: 2px 2px 3px lightgrey;
	}
`;

export const ChartType = styled.div`
	margin-left: auto;

	font-size: 1.5em;
	padding-right: 5px;
	color: grey;

	& svg {
		padding-right: 10px;
	}
`;

export const Header = styled.div`
	width: 100%;
	color: grey;
	font-weight: 500;
	padding: 20px 0;
`;