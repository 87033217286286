import React from 'react';
import classNames from 'classnames';
import { RawConfig } from '../../contexts/auth';
import Dropzone from 'react-dropzone';
import { Wrapper, Heart } from './components';
import Upload from '../../assets/uploadCSV.svg';
import Hover from '../../assets/hover.svg';


class MyDropzone extends React.Component {
  state = {
    loading: false,
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    //console.log(acceptedFiles[0].type);
    this.props.fileUploading();
    var reader = new FileReader();
    fetch(RawConfig.base_url_api + '/data/upload', {
      method: "POST",
      headers: {
        'health-auth': localStorage.getItem('id_token'),
        'Content-Type': 'text/csv'
      },
      body: JSON.stringify({
        name: acceptedFiles[0].name,
        type: acceptedFiles[0].type
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return fetch(json.uploadURL, {
          method: "PUT",
          body: new Blob([reader.result], { type: acceptedFiles[0].type })
        })
      })
      .then(() => {
        this.props.fileUploaded();
        fetch(RawConfig.base_url_api + '/data/done', {
          method: "POST",
          headers: {
            'health-auth': localStorage.getItem('id_token'),
          }
        })
      });
    reader.readAsArrayBuffer(acceptedFiles[0]);
    return false;
  }

  render() {
    return (
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <Wrapper
              {...getRootProps()}
              className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
            >
              <input {...getInputProps()} />
              <Heart src={isDragActive ? Hover : Upload} />
            </Wrapper>
          )
        }}
      </Dropzone>
    );
  }
}

export default MyDropzone;