import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import Axios from 'axios';
import { healthGet } from '../../utils';
import { LoginWrapper } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import theme from '../../theme';

export default class Login extends Component {
	state = {
		done: false,
	}
	componentDidMount() {
		// get params from URL
		const params = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
			params[key] = value;
		});

		const { code } = params;
		if (!code) {
			this.setState({ error: 'No code received' });
		} else {
			this.authUser(code);
		}
	}

	async authUser(code) {
		let domain = '';
		let protocol = '';
		let client_id = '';
		if (process.env.NODE_ENV === 'development') {
			protocol = 'https';
			domain = 'localhost:3001';
			client_id = '49ketlr5tqdukv88omai6jphr0';
		} else {
			protocol = 'https';
			domain = 'openhealthboard.com';
			client_id = 'kia5571p4f4s9ggc06atju65u';
		}

		const obj = {
			grant_type: 'authorization_code',
			redirect_uri: `${protocol}://${domain}/login`,
			code,
			client_id,
		};

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			data: qs.stringify(obj),
			url: 'https://openhealthboard.auth.us-east-1.amazoncognito.com/oauth2/token'
		};

		try {
			const res = await Axios(options);
			const { data } = res;
			const { id_token, refresh_token } = data;
			localStorage.setItem('id_token', id_token);
			localStorage.setItem('refresh_token', refresh_token);
			window.healthAuth.isAuthenticated = !!window.localStorage.getItem('id_token');
			const parsed = JSON.parse(atob(id_token.split('.')[1]));
			const username = parsed['cognito:username'];
			const { email } = parsed;
			localStorage.setItem('username', username);
			localStorage.setItem('email', email);
			localStorage.setItem('admin', parsed['custom:admin']);
			window.healthAuth.isAdmin = parsed['custom:admin'] === 'true';
			const status = await healthGet('/data/status');
			const user = status.data;
			localStorage.setItem('friends', user.friends);
			localStorage.setItem('goals', user.goals);
			this.setState({ done: true, uploaded: user.uploaded });
		} catch (e) {
			window.healthAuth.isAuthenticated = false;
			window.healthAuth.isAdmin = false;
			this.setState({ error: true });
		}
	}
	render() {
		const { done, error, uploaded } = this.state;
		if (done && uploaded) {
			return <Redirect to="/dashboard" />;
		}
		if (done) {
			return <Redirect to="/uploadfile" />;
		}
		if (error) {
			return <div>{error}</div>;
		}
		return (
			<LoginWrapper>
				<FontAwesomeIcon icon={faSpinner} spin color={theme.pink.dark} />
				<br />
				logging you in securely...
			</LoginWrapper>
		);
	}
}
