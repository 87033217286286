const primary = {
	pink: {
		light: '#F4468F',
		dark: '#F40E6F',
	},
	blue: {
		light: '#56EAE0',
		dark: '#5285DD',
	},
	green: {
		light: '#7A8C20',
		dark: '#4D5914',
	},
	yellow: {
		light: '#E2C60D',
		dark: '#D3970C',
	},
	red: {
		light: '#C1302E',
		dark: '#870402',
	},
	light: 'white',
	dark: 'black',
}

const dark = {
	pink: {
		light: '#F4468F',
		dark: '#F40E6F',
	},
	blue: {
		light: '#56EAE0',
		dark: '#5285DD',
	},
	green: {
		light: '#7A8C20',
		dark: '#4D5914',
	},
	yellow: {
		light: '#E2C60D',
		dark: '#D3970C',
	},
	red: {
		light: '#C1302E',
		dark: '#870402',
	},
	light: 'black',
	dark: 'white',
}

export default primary;

export const withTheme = function (ident) {
	if (ident === 'light') {
		return primary;
	} else {
		return dark;
	}
}