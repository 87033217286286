import styled from 'styled-components';
import theme from '../../theme';

export const ModalWrapper = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
`;

export const ModalWindow = styled.div`
    width: calc(100% - 120px);
    height: calc(100% - 120px);
    display: flex;
    margin: 0 auto;
    box-shadow: 1px 1px 2px black;
	position: relative;
	background-color: ${props => (props.theme === 'dark' ? '#222222' : 'white')};
    transition: background-color 1s;

    & .ct-grid {
        stroke: ${props => (props.theme === 'light' ? 'black' : 'white')};
    }

    & .ct-label {
        color: ${props => (props.theme === 'light' ? 'black' : 'white')};
    }
    
    & svg.close {
        position: absolute;
        right: -5px;
        top: -5px;
        font-size: 2em;
        color: grey;
    }
`;

export const Value = styled.div`
    font-weight: 500;
    text-align: left;
    font-size: 2em;
    padding-left: 10px;
    color: ${props => (props.theme === 'dark' ? 'white' : 'black')};
    transition: 1s;
`;

export const StatTitle = styled.div`
	color: ${props => (props.theme === 'dark' ? 'white' : 'grey')};
	text-align: left;
    padding-left: 10px;
    font-size: 1.2em;
    ${props => (props.push ? ' padding-top: 10px;' : '')}
`;

export const ChartWrapper = styled.div`
    @keyframes healthFadeIn {
        0% { opacity: 0 }
        100% { opacity: 1 }
    }

    ${props => (props.height ? `
        height: ${props.height};
        & .ct-chart {
            height: 100%;
        }
    ` : '')}

    width: 100%;
    margin: 0 auto;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;

    & svg {
        transform: translateY(3px);
        opacity: 0;
        animation: healthFadeIn 1s forwards;
    }

    & .ct-series-a .ct-area {
        fill: ${props => props.fillColor};
    }

    & .ct-series-a .ct-line {
        stroke: ${props => props.lineColor};
    }
`;

export const RightActions = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    color: grey;

    & svg {
        padding-right: 4px;
    }
`;

export const NoData = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    color: grey;

    & div {
        margin: 0 auto;
    }

    & svg {
        font-size: 2em;
        color: lightgrey;
    }
`;

export const Fact = styled.div`
    text-align: left;
    padding-left: 10px;
    padding-top: 15px;
    font-weight: 100;
    width: 90%;
    color: ${props => (props.theme === 'dark' ? 'white' : 'grey')};
    transition: color 1s;
    font-size: 0.9em;
`;

export const Citation = styled.a`
    color: ${theme.pink.dark};
    text-decoration: none;
`;

export const Tileloader = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    & svg {
        margin: 0 auto;
        font-size: 2em;
        color: pink;
    }
`;

export const ModalTitle = styled.div`
    color: ${props => (props.theme === 'dark' ? 'white' : 'grey')};
    font-weight: 400;
    padding: 10px 0 0 10px;
    font-size: 1.5em;
`;