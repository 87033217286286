import styled from 'styled-components';
import { keyframes } from 'styled-components';


export const Wrapper = styled.div`
    width: 100%;
    height: 50px;
    align-items: center;
`;

const mymove = keyframes`
 0% {transform: Scale(1)}
 
 50% {transform: Scale(.85)}

 100% {transform: Scale(1)}
`;

export const Heart = styled.img`
    width: 400px;
    height: 400px;
    animation: ${mymove} 2.5s infinite;

`;