import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import './App.css';
import Nav from './components/nav';
import Home from './screens/home';
import Login from './screens/login';
import Dashboard from './screens/dashboard';
import Uploadfile from './screens/uploadfile';
import Logout from './screens/logout';

const PrivateRoute = ({ component: Component, theme, ...rest }) => (
  <Route {...rest} render={(props) => (
    !window.healthAuth.isAuthenticated
      ? <Redirect to='/' />
      : <Component {...props} theme={theme} />
  )} />
);

const PublicOnlyRoute = ({ component: Component, theme, ...rest }) => (
  <Route {...rest} render={(props) => (
    window.healthAuth.isAuthenticated
      ? <Redirect to='/dashboard' />
      : <Component {...props} theme={theme} />
  )} />
);


class App extends Component {
  state = {
    theme: localStorage.getItem('theme') || 'light'
  }

  toggleTheme() {
    this.setState((prevState) => {
      const newTheme = prevState.theme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newTheme);
      return {
        theme: newTheme,
      };
    });
  }
  render() {
    return (
      <Router>
        <div style={{ backgroundColor: this.state.theme === 'dark' ? '#444444' : 'white' }}>
          <Nav toggleTheme={() => this.toggleTheme()} theme={this.state.theme} />
          <PublicOnlyRoute path="/" exact component={Home} theme={this.state.theme} />
          <PublicOnlyRoute path="/login" component={Login} theme={this.state.theme} />
          <PrivateRoute path="/dashboard" component={Dashboard} theme={this.state.theme} />
          <PrivateRoute path="/uploadfile" component={Uploadfile} theme={this.state.theme} />
          <PublicOnlyRoute path="/logout" component={Logout} theme={this.state.theme} />
        </div>
      </Router>
    );
  }
}

export default App;
