import React, { Component } from 'react';
import { ScreenWrapper, TileWrapper } from '../../components/general';
import Tile from '../../components/tile';
import { AuthContext } from '../../contexts/auth';
import { Row, Pill, Header } from './components';
import theme from '../../theme';
import { Left } from '../../components/nav/components';
import AggregateTile from '../../components/tile/aggregate';


class Dashboard extends Component {
	state = {
		dateRange: 30,
		comparisonArray: [],
		chartType: 'Line'
	}

	addComparison(target) {
		this.setState((prevState) => {
			if (prevState.comparisonArray.indexOf(target) < 0) {
				const newArray = prevState.comparisonArray;
				newArray.push(target)
				return {
					comparisonArray: newArray,
				};
			}
			return null;
		});
	}

	removeComparison(target) {
		this.setState((prevState) => {
			return {
				comparisonArray: prevState.comparisonArray.filter((item) => item !== target),
			};
		});
	}

	render() {
		return (
			<ScreenWrapper>
				<Header>
					Hello {localStorage.getItem('username')}
				</Header>
				<Row>
					<TileWrapper collapse theme={this.props.theme}>
						<Left>
							<Pill
								active={this.state.dateRange === 30}
								onClick={() => {
									this.setState({ dateRange: 30 })
								}}
							>
								30 days
							</Pill>
							<Pill
								active={this.state.dateRange === 60}
								onClick={() => {
									this.setState({ dateRange: 60 })
								}}
							>
								60 days
							</Pill>
							<Pill
								active={this.state.dateRange === 90}
								onClick={() => {
									this.setState({ dateRange: 90 })
								}}
							>
								90 days
							</Pill>
							<Pill
								active={this.state.dateRange === 180}
								onClick={() => {
									this.setState({ dateRange: 180 })
								}}
							>
								6 months
							</Pill>
							<Pill
								active={this.state.dateRange === 365}
								onClick={() => {
									this.setState({ dateRange: 365 })
								}}
							>
								1 year
							</Pill>
						</Left>
					</TileWrapper>
				</Row>
				{
					this.state.comparisonArray.length > 1 ? (
						<Row>
							<AggregateTile
								dataPoints={this.state.comparisonArray}
								dateRange={this.state.dateRange}
								theme={this.props.theme}
							/>
						</Row>
					) : null
				}
				<Row>
					<Tile
						title="Steps Taken"
						dataPoint="steps"
						units="steps"
						lineColor={theme.blue.light}
						pointColor={theme.blue.dark}
						dateRange={this.state.dateRange}
						setComparison={(item) => this.addComparison(item)}
						deleteComparison={(item) => this.removeComparison(item)}
						checked={this.state.comparisonArray.indexOf('steps') >= 0}
						type={this.state.chartType}
						theme={this.props.theme}
					>
					</Tile>
					<Tile
						title="Active Energy"
						dataPoint="active-energy"
						units="kcal"
						showUnit
						lineColor={theme.red.light}
						pointColor={theme.red.dark}
						dateRange={this.state.dateRange}
						setComparison={(item) => this.addComparison(item)}
						deleteComparison={(item) => this.removeComparison(item)}
						checked={this.state.comparisonArray.indexOf('active-energy') >= 0}
						type={this.state.chartType}
						theme={this.props.theme}
					>
					</Tile>
					<Tile
						title="Distance Walked"
						dataPoint="distance"
						units="miles"
						showUnit
						lineColor={theme.green.light}
						pointColor={theme.green.dark}
						dateRange={this.state.dateRange}
						setComparison={(item) => this.addComparison(item)}
						deleteComparison={(item) => this.removeComparison(item)}
						checked={this.state.comparisonArray.indexOf('distance') >= 0}
						type={this.state.chartType}
						theme={this.props.theme}
					>
					</Tile>
				</Row>
				<Row>
					<Tile
						title="Average Heart Rate"
						dataPoint="heart-rate"
						units="bpm"
						mean
						truncate
						showUnit
						lineColor={theme.pink.light}
						pointColor={theme.pink.dark}
						dateRange={this.state.dateRange}
						setComparison={(item) => this.addComparison(item)}
						deleteComparison={(item) => this.removeComparison(item)}
						checked={this.state.comparisonArray.indexOf('heart-rate') >= 0}
						type={this.state.chartType}
						theme={this.props.theme}
					>
					</Tile>
					<Tile
						title="Average Weight"
						dataPoint="weight"
						units="lbs"
						mean
						showUnit
						truncate
						lineColor={theme.yellow.light}
						pointColor={theme.yellow.dark}
						dateRange={this.state.dateRange}
						setComparison={(item) => this.addComparison(item)}
						deleteComparison={(item) => this.removeComparison(item)}
						checked={this.state.comparisonArray.indexOf('weight') >= 0}
						type={this.state.chartType}
						theme={this.props.theme}
					>
					</Tile>
				</Row>
			</ScreenWrapper>
		);
	}
};

Dashboard.contextType = AuthContext;
export default Dashboard;
