import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import { StatTitle } from './components';
import { TileWrapper } from '../general';
import { healthGet } from '../../utils';
import theme from '../../theme';


class AggregateTile extends Component {
    state = {
        loading: true,
        data: null,
        initial: false,
    }

    async retrieveData() {
        await this.setState({ data: null, loading: true });
        const res = await Promise.all(this.props.dataPoints.map((name) => healthGet(`/data/${name}`, null, name)));
        console.log(res);
        this.setState({
            data: res,
            loading: false,
        });
    }

    componentDidMount() {
        this.retrieveData();
    }

    componentWillReceiveProps() {
        this.retrieveData();
    }

    render() {
        const labels = this.state.loading ? [] : this.state.data[0].data.filter((point) => moment(point.startDate) > moment().subtract(this.props.dateRange, 'day')).map((point) => moment(point.startDate).format('MMM DD, YYYY'));
        const series = this.state.loading ? [] : this.state.data.map((set) => {
            return {
                data: set.data.filter((point) => moment(point.startDate) > moment().subtract(this.props.dateRange, 'day')).map((point) => Math.round(point.value)),
                meta: set.meta,
            };
        });
        const data = {
            labels,
            series,
        };
        const colorsDark = [theme.pink.dark, theme.green.dark, theme.red.dark, theme.yellow.dark, theme.blue.dark];
        const colorsLight = [theme.pink.light, theme.green.light, theme.red.light, theme.yellow.light, theme.blue.light];
        return (!this.state.loading ? (
            <TileWrapper animate loaded={!this.state.loading} theme={this.props.theme}>
                {console.log(data)}
                <StatTitle push theme={this.props.theme}>Compare Data </StatTitle>
                <div style={{ height: '250px' }}>
                    <Line
                        data={{
                            labels,
                            datasets: series.map((single, index) => {
                                return {
                                    label: single.meta.replace(/-/g, ' '),
                                    data: single.data,
                                    fill: false,
                                    backgroundColor: [colorsDark[index]],
                                    borderColor: [colorsLight[index]],
                                };
                            })
                        }}
                        height={300}
                        options={{
                            maintainAspectRatio: false,
                        }}
                    />
                </div>
            </TileWrapper>) : <TileWrapper animate loaded={!this.state.loading} theme={this.props.theme}><FontAwesomeIcon icon={faSpinner} spin className="aggregate-loader" /></TileWrapper>);
    }
};

export default AggregateTile;
