import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faSpinner, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Value, StatTitle, ChartWrapper, RightActions, NoData, Fact, Citation, Tileloader, ModalWrapper, ModalWindow, ModalTitle } from './components';
import { TileWrapper } from '../general';
import { healthGet } from '../../utils';
import fastFacts from '../../fastfacts.json';


class Tile extends Component {
    state = {
        loading: true,
        data: null,
        initial: false,
        showModal: false,
    }

    async retrieveData() {
        const res = await healthGet(`/data/${this.props.dataPoint}`);
        if (res.data && res.data.length) {
            this.setState({
                loading: false,
                data: res.data,
                initial: true,
            });
        }
    }

    toggleModal() {
        console.log('here');
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
    }

    componentDidMount() {
        this.retrieveData();
    }

    getContent(goodData) {
        if (this.state.loading) {
            return (
                <Tileloader>
                    <FontAwesomeIcon spin icon={faSpinner} />
                </Tileloader>
            );
        }
        if (goodData) {
            return (
                <RightActions>
                    <FontAwesomeIcon icon={faBalanceScale} />
                    <input
                        type="checkbox"
                        checked={this.props.checked}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => {
                            if (this.props.checked) {
                                this.props.deleteComparison(this.props.dataPoint);
                            } else {
                                this.props.setComparison(this.props.dataPoint);
                            }
                        }}
                    />
                </RightActions>
            );
        }
        return (
            <NoData>
                <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <br />
                    No data for the selected range
                </div>
            </NoData>
        );
    }

    render() {
        const facts = fastFacts[this.props.dataPoint] || [];
        const fastFact = facts[Math.floor(Math.random() * (facts.length - 0) + 0)];
        const data = {
            labels: this.state.loading ? [] : this.state.data.filter((point) => {
                if (this.props.truncate && point.value === 0) {
                    return false;
                }
                return moment(point.startDate) > moment().subtract(this.props.dateRange, 'day')
            }).map((point) => moment(point.startDate).format('MMM DD, YYYY')),
            series: this.state.loading ? [] : [this.state.data.filter((point) => moment(point.startDate) > moment().subtract(this.props.dateRange, 'day')).map((point) => Math.round(point.value)).filter((value) => {
                if (this.props.truncate) {
                    return value !== 0;
                }
                return true;
            })]
        };

        const aggregate = !this.state.loading
            ? Math.round((data.series[0].reduce((prev, curr) => prev + curr, 0)) / (this.props.mean ? data.series[0].length : 1))
            : null;

        const goodData = data.series
            && data.series[0]
            && data.series[0].length
            && aggregate
            && aggregate > 0
            && !isNaN(aggregate);

        let details;
        this.state.showModal ? details = (
            <ModalWrapper onClick={() => this.toggleModal}>
                <ModalWindow theme={this.props.theme}>
                   <ModalTitle theme={this.props.theme}>{this.props.title}</ModalTitle>
                    <FontAwesomeIcon
                        className="close"
                        icon={faTimesCircle}
                        onClick={() => this.toggleModal()}
                        style={{ cursor: 'pointer' }}
                    />
                    <ChartWrapper lineColor={this.props.pointColor} fillColor={this.props.lineColor} height="90%">
                        {
                            !this.state.loading && goodData ? (
                                <ChartistGraph
                                    options={{
                                        showLabel: true,
                                        showGrid: true,
                                        showArea: true,
                                        fullWidth: true,
                                        showPoint: true,
                                    }}
                                    height={600}
                                    type={this.props.type}
                                    data={data}
                                />
                            ) : null
                        }
                    </ChartWrapper>
                </ModalWindow>
            </ModalWrapper>
        ) : details = null;

        return (
            <React.Fragment>
                {details}
                <TileWrapper onClick={() => this.toggleModal()} animate loaded={!this.state.loading && goodData} theme={this.props.theme}>
                    <Value theme={this.props.theme}>
                        {goodData ? aggregate.toLocaleString() : null} {goodData && this.props.showUnit ? this.props.units : null}
                    </Value>
                    {this.getContent(goodData)}
                    <StatTitle theme={this.props.theme}> {goodData ? this.props.title : null} </StatTitle>
                    <Fact theme={this.props.theme}>{fastFact && goodData ? (
                        <span>
                            {fastFact.text}.<Citation href={fastFact.source} target="_blank" rel="noopener noreferrer">* </Citation>
                            {fastFact.action
                                ? (
                                    <span>
                                        {fastFact.action} {Math.round(aggregate / this.props.dateRange)}.
                                </span>
                                ) : null
                            }
                        </span>
                    ) : null}</Fact>
                    <ChartWrapper lineColor={this.props.pointColor} fillColor={this.props.lineColor}>
                        {
                            !this.state.loading && goodData ? (
                                <ChartistGraph
                                    options={{
                                        showLabel: false,
                                        axisX: {
                                            showGrid: false,
                                            showLabel: false,
                                            offset: 0
                                        },
                                        axisY: {
                                            showGrid: false,
                                            showLabel: false,
                                            offset: 0
                                        },
                                        showGrid: false,
                                        showArea: true,
                                        fullWidth: true,
                                        chartPadding: 0,
                                        showPoint: false,
                                        low: 0,
                                    }}
                                    type={this.props.type}
                                    data={data}
                                />
                            ) : null
                        }
                    </ChartWrapper>
                </TileWrapper>
            </React.Fragment>
        );
    }
};

export default Tile;
