import styled from 'styled-components';
import theme from '../../theme';

export const Header = styled.div`
    color: ${theme.pink};
    font-size: 50px;
    text-align: center;
    margin: 20px;
`;

export const SubHeader = styled.div`
    text-align: center;
    font-size: 20px;
`;

export const Space = styled.div`
    width: 100%;
    height: 200px;
`;

export const HelpContainer = styled.div`
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 100px;
    hieght: 100px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: horizontal;
    align-items: center;
`;

export const Pic = styled.img`
    margin: 0 auto;
    padding-top: 10px;
    
`;

export const ExitContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
`;

export const Description = styled.div`
    font-size: 18px;
    bottom: 0;
    margin: auto auto;

`;