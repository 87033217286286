import React, { Component } from 'react';
import { ScreenWrapper } from '../../components/general';
import MyDropzone from '../../components/dropzone';
import Modal from '../../components/modal';
import { AuthContext } from '../../contexts/auth';
import { Header, SubHeader, Space, HelpContainer, Container, Pic, ExitContainer, Description } from './components';
import { faSpinner, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppleStore from '../../assets/AppleStore.PNG'
import AppleVerfication from '../../assets/AppleHealthVerification.PNG'
import QSAccess from '../../assets/QSAcess.PNG';
import { healthGet } from '../../utils';

class Uploadfile extends Component {

    state = {
        fileUploaded: false,
        fileUploading: false,
        showModal: false,
    }

    toggleHelp() {
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
    }

    async success() {
        this.setState({
            fileUploaded: true,
            fileUploading: false,
        });
        await healthGet('/data/register');
        return window.location.href = '/dashboard';
    }

    uploading() {
        this.setState({ fileUploading: true });
    }

    render() {
        let Help = (
            <Modal color={'#ffffff'}>
                <ExitContainer>
                    <FontAwesomeIcon onClick={() => this.toggleHelp()} icon={faTimesCircle} size='2x' color='grey' />
                </ExitContainer>
                <SubHeader> How to Upload your Apple Health Data</SubHeader>
                <Container>
                    <Space>
                        <Pic src={AppleStore} width='150' height='325' />
                    </Space>
                    <Space>
                        <Pic src={AppleVerfication} width='150' height='325' />
                    </Space>
                    <Space>
                        <Pic src={QSAccess} width='150' height='325' />
                    </Space>
                </Container>
                <Space />
                <Description>
                    Download the App QS Access from the App Store to retrieve your Apple Health Data.<br />
                    QS Access will format the data into an CSV File that we can use to create your dashboard.<br />
                    Your Health data is stored and transferred securely.<br />
                </Description>
            </Modal>
        )
        let conditionalRender
        if (this.state.fileUploaded) {
            conditionalRender = (
                <span>
                    <Space />
                    <div>Currently Making Your Board. Check Back Soon</div>
                    <br />
                    <br />
                    <FontAwesomeIcon icon={faSpinner} color={'pink'} spin size={'6x'} />
                </span>
            )
        } else if (this.state.fileUploading) {
            conditionalRender = (
                <span>
                    <Space />
                    <FontAwesomeIcon icon={faSpinner} color={'pink'} spin size={'6x'} />
                </span>
            )
        } else {
            conditionalRender = (
                <span>
                    <Header> Let's Create Your Health Board </Header>
                    <SubHeader> Upload Your Apple Health CSV File and Get Going.</SubHeader>
                    <MyDropzone
                        fileUploaded={() => this.success()}
                        fileUploading={() => this.uploading()}
                    />
                </span>
            )
        }
        return (
            <ScreenWrapper>
                {this.state.showModal ? Help : null}
                {conditionalRender}
                <HelpContainer onClick={() => this.toggleHelp()}>
                    <FontAwesomeIcon icon={faQuestionCircle} color={'grey'} size={'2x'} />
                </HelpContainer>
            </ScreenWrapper>
        );
    }
};

Uploadfile.contextType = AuthContext;
export default Uploadfile;