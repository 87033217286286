import React, { Component } from 'react';
import { Wrapper } from './components';

class Modal extends Component {

    render() {
        return (
            <Wrapper color={this.props.color}>
                {this.props.children}
            </Wrapper>
        );
    }
};

export default Modal;