import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 75%;
    margin: 10px auto;
    position: fixed; 
    background-color: ${props => props.color}
    border: 1px solid lightgrey;
    box-shadow: 1px 1px 2px lightgrey
    z-index: 99;
`;

